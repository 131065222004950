<template>
  <div class="popup_wrap" style="width:500px;">
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title mt0">{{ pageTitle }}</h1>
      <div class="content_box" v-html="apvStsRmk">
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import calcIssueApi from '@/api/rest/settle/calcIssue'
import { rootComputed } from '@/store/helpers'
import myRequest from '@/api/rest/trans/myRequest'
import boards from '@/api/services/boards'

export default {
  name: 'CalcIssueInvMsgPop',
  components: {},
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          apvStsCd: '',
          reqRspsLst: '',
          blNo: ''
        }
      }
    }
  },
  data: function () {
    return {
      apvStsRmk: '',
      pageTitle: '', //'제목',
      process: {
        state: '',
        reason: '',
        user: '',
        address: ''
      }
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    this.pageTitle = '처리상태 및 담당자'
    this.setData()
  },
  mounted () {
  },
  methods: {
    setData () {
      this.displayMsg()
    },
    async displayMsg () {
      this.apvStsRmk = this.parentInfo.freeTimeInvoice.apvStsRmk
    }
  }
}
</script>
