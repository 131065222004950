var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "p",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.auth.userCtrCd === "ID" && _vm.isFirstCalcTableIndex,
            expression: "auth.userCtrCd === 'ID' && isFirstCalcTableIndex",
          },
        ],
        staticStyle: { "text-align": "right" },
      },
      [
        _vm._v(
          " When the freetime extention applied, additional ADM extention charge will be added as a B/L auxiliary charge. "
        ),
      ]
    ),
    _vm.table.payload.rows.length >= 0 && _vm.query !== undefined
      ? _c(
          "div",
          {
            staticClass: "freetime_sec",
            attrs: { id: "tb_" + _vm.tableInfo.idx },
          },
          [
            _c("div", { staticClass: "flex_box mt20" }, [
              _c("h2", { staticClass: "content_title" }, [
                _vm._v(_vm._s(_vm.table.title)),
              ]),
              _vm.$ekmtcCommon.isNotEmpty(_vm.table.payload.freeTimeInvoice)
                ? _c("span", { staticClass: "txt_link" }, [
                    _vm.table.payload.freeTimeInvoice.vaAcctYn === "Y"
                      ? _c("span", [
                          _vm.table.payload.freeTimeInvoice.apvStsCd === "04" ||
                          _vm.table.payload.freeTimeInvoice.apvStsCd === "10"
                            ? _c("div", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: {
                                      "font-weight": "bold",
                                      color: "red",
                                    },
                                    attrs: {
                                      id: "invLink",
                                      href: "javascript:void(0)",
                                    },
                                    on: {
                                      mouseenter: function ($event) {
                                        _vm.isToolTipInvMsg = true
                                      },
                                      mouseleave: function ($event) {
                                        _vm.isToolTipInvMsg = false
                                      },
                                      click: function ($event) {
                                        return _vm.openPopup(
                                          "CalcIssueInvMsgPop"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getChangeText(
                                          _vm.table.payload.freeTimeInvoice
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ])
                            : _c("div", [
                                _c(
                                  "a",
                                  {
                                    staticStyle: {
                                      "font-weight": "bold",
                                      color: "black",
                                    },
                                    attrs: {
                                      id: "invLink",
                                      href: "javascript:void(0)",
                                    },
                                    on: {
                                      mouseenter: function ($event) {
                                        _vm.isToolTipInvMsg = true
                                      },
                                      mouseleave: function ($event) {
                                        _vm.isToolTipInvMsg = false
                                      },
                                      click: function ($event) {
                                        return _vm.openPopup(
                                          "CalcIssueInvMsgPop"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getChangeText(
                                          _vm.table.payload.freeTimeInvoice
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                        ])
                      : _vm.table.payload.freeTimeInvoice.apvStsCd === "04" ||
                        _vm.table.payload.freeTimeInvoice.apvStsCd === "07" ||
                        _vm.table.payload.freeTimeInvoice.apvStsCd === "10"
                      ? _c("span", [
                          _c(
                            "a",
                            {
                              staticStyle: {
                                "font-weight": "bold",
                                color: "red",
                              },
                              attrs: {
                                id: "invLink",
                                href: "javascript:void(0)",
                              },
                              on: {
                                mouseenter: function ($event) {
                                  _vm.isToolTipInvMsg = true
                                },
                                mouseleave: function ($event) {
                                  _vm.isToolTipInvMsg = false
                                },
                                click: function ($event) {
                                  return _vm.openPopup("CalcIssueInvMsgPop")
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getChangeText(
                                    _vm.table.payload.freeTimeInvoice
                                  )
                                )
                              ),
                            ]
                          ),
                        ])
                      : _c("span", [
                          _c(
                            "a",
                            {
                              staticStyle: {
                                "font-weight": "bold",
                                color: "black",
                              },
                              attrs: {
                                id: "invLink",
                                href: "javascript:void(0)",
                              },
                              on: {
                                mouseenter: function ($event) {
                                  _vm.isToolTipInvMsg = true
                                },
                                mouseleave: function ($event) {
                                  _vm.isToolTipInvMsg = false
                                },
                                click: function ($event) {
                                  return _vm.openPopup("CalcIssueInvMsgPop")
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getChangeText(
                                    _vm.table.payload.freeTimeInvoice
                                  )
                                )
                              ),
                            ]
                          ),
                        ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.isToolTipInvMsg &&
                              !_vm.$ekmtcCommon.isEmpty(
                                _vm.table.payload.freeTimeInvoice.apvStsRmk
                              ),
                            expression:
                              "isToolTipInvMsg && !$ekmtcCommon.isEmpty(table.payload.freeTimeInvoice.apvStsRmk)",
                          },
                        ],
                        staticClass: "tooltip_wrap short position_absolute",
                        staticStyle: {
                          "min-width": "350px",
                          "min-height": "150px",
                        },
                      },
                      [
                        _c("div", { staticClass: "cont" }, [
                          _c(
                            "button",
                            {
                              staticClass: "close",
                              on: {
                                click: function ($event) {
                                  _vm.isToolTipInvMsg = false
                                },
                              },
                            },
                            [_vm._v("close")]
                          ),
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.table.payload.freeTimeInvoice.apvStsRmk
                              ),
                            },
                          }),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _c("span", { staticClass: "cal_btn" }, [
                _c("span", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.isOnlyCost,
                        expression: "isOnlyCost",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "isOnlyCost-" + _vm.table.id,
                    },
                    domProps: {
                      checked: Array.isArray(_vm.isOnlyCost)
                        ? _vm._i(_vm.isOnlyCost, null) > -1
                        : _vm.isOnlyCost,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.isOnlyCost,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.isOnlyCost = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.isOnlyCost = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.isOnlyCost = $$c
                        }
                      },
                    },
                  }),
                  _c(
                    "label",
                    { attrs: { for: "isOnlyCost-" + _vm.table.id } },
                    [_vm._v(_vm._s(_vm.$t("msg.SETT060G010.016")))]
                  ),
                ]),
              ]),
            ]),
            _c("div", { style: _vm.headerFooterCss }, [
              _c(
                "table",
                {
                  staticClass: "tbl_col",
                  staticStyle: { "border-bottom": "none" },
                },
                [
                  _c("colgroup", [
                    _c("col", { staticStyle: { width: "3%" } }),
                    _c("col", { staticStyle: { width: "12%" } }),
                    _c("col", { staticStyle: { width: "11%" } }),
                    _c("col", { staticStyle: { width: "8%" } }),
                    _c("col", { staticStyle: { width: "6%" } }),
                    _c("col", { staticStyle: { width: "8%" } }),
                    _c("col", { staticStyle: { width: "12%" } }),
                    _c("col", { staticStyle: { width: "8%" } }),
                    _c("col", { staticStyle: { width: "8%" } }),
                    _c("col", { staticStyle: { width: "12%" } }),
                    _vm.ctrCd === "KR" || _vm.ctrCd === "PH" || _vm.vatChk
                      ? _c("col", { staticStyle: { width: "12%" } })
                      : _vm._e(),
                  ]),
                  _c("thead", [
                    _c("tr", [
                      _c(
                        "th",
                        { staticClass: "pr0", attrs: { scope: "col" } },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.isCheckAll,
                                expression: "isCheckAll",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              id: "isCheckAll-" + _vm.table.id,
                            },
                            domProps: {
                              checked: Array.isArray(_vm.isCheckAll)
                                ? _vm._i(_vm.isCheckAll, null) > -1
                                : _vm.isCheckAll,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.checkAll()
                              },
                              change: function ($event) {
                                var $$a = _vm.isCheckAll,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.isCheckAll = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.isCheckAll = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.isCheckAll = $$c
                                }
                              },
                            },
                          }),
                          _c(
                            "label",
                            { attrs: { for: "isCheckAll-" + _vm.table.id } },
                            [
                              _c("span", { staticClass: "offscreen" }, [
                                _vm._v("선택"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c("th", { attrs: { scope: "col" } }, [_vm._v("Port")]),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.SETT060G010.052"))),
                      ]),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v("Start Date"),
                      ]),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.SETT060G010.051"))),
                      ]),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(
                          _vm._s(_vm.table.typeText) +
                            " " +
                            _vm._s(_vm.$t("msg.SETT060G010.033"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.SETT060G010.034"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.SETT060G010.050"))),
                      ]),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.SETT060G010.011"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.SETT060G010.012"))),
                      ]),
                      _vm._v(" "),
                      _vm.ctrCd === "KR" || _vm.ctrCd === "PH" || _vm.vatChk
                        ? _c("th", { attrs: { scope: "col" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("msg.SETT060G010.012")) + "(VAT)"
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "body-table-div" }, [
              _c(
                "table",
                {
                  staticClass: "tbl_col",
                  staticStyle: { "border-bottom": "none" },
                },
                [
                  _c("colgroup", [
                    _c("col", { staticStyle: { width: "3%" } }),
                    _c("col", { staticStyle: { width: "12%" } }),
                    _c("col", { staticStyle: { width: "11%" } }),
                    _c("col", { staticStyle: { width: "8%" } }),
                    _c("col", { staticStyle: { width: "6%" } }),
                    _c("col", { staticStyle: { width: "8%" } }),
                    _c("col", { staticStyle: { width: "12%" } }),
                    _c("col", { staticStyle: { width: "8%" } }),
                    _c("col", { staticStyle: { width: "8%" } }),
                    _c("col", { staticStyle: { width: "12%" } }),
                    _vm.ctrCd === "KR" || _vm.ctrCd === "PH" || _vm.vatChk
                      ? _c("col", { staticStyle: { width: "12%" } })
                      : _vm._e(),
                  ]),
                  _c(
                    "tbody",
                    [
                      _vm.table.payload.rows.length === 0
                        ? _c("tr", [
                            _c(
                              "td",
                              { attrs: { colspan: "9" } },
                              [
                                _vm.table.id === "DEM"
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("msg.SETT060G010.035"))
                                      ),
                                    ]
                                  : _vm.table.id === "DET"
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("msg.SETT060G010.036"))
                                      ),
                                    ]
                                  : _vm.table.id === "COM"
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("msg.SETT060G010.037"))
                                      ),
                                    ]
                                  : _vm.table.id === "OFC"
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("msg.SETT060G010.038"))
                                      ),
                                    ]
                                  : _vm.table.id === "MOR"
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("msg.SETT060G010.039"))
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm.query.eiCatCd === "O"
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("msg.SETT060G010.040"))
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm.query.eiCatCd === "I"
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("msg.SETT060G010.041"))
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ])
                        : _vm._e(),
                      _vm._l(_vm.table.payload.rows, function (row, i) {
                        return [
                          row.isShow
                            ? _c(
                                "tr",
                                {
                                  key: "tr-" + _vm.table.id + "-" + i,
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickRow(i, $event)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "td",
                                    { staticClass: "pr0" },
                                    [
                                      row.hasInvoice === "N"
                                        ? [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: row.isChecked,
                                                  expression: "row.isChecked",
                                                },
                                              ],
                                              staticStyle: {
                                                position: "initial",
                                              },
                                              attrs: {
                                                type: "checkbox",
                                                id:
                                                  "checkbox-" +
                                                  _vm.table.id +
                                                  "-" +
                                                  i,
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  row.isChecked
                                                )
                                                  ? _vm._i(
                                                      row.isChecked,
                                                      null
                                                    ) > -1
                                                  : row.isChecked,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a = row.isChecked,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          row,
                                                          "isChecked",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          row,
                                                          "isChecked",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      row,
                                                      "isChecked",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticStyle: {
                                                  "pointer-eventes": "none",
                                                },
                                                attrs: {
                                                  for:
                                                    "checkbox-" +
                                                    _vm.table.id +
                                                    "-" +
                                                    i,
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "offscreen" },
                                                  [_vm._v("선택")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                  _c("td", [_vm._v(_vm._s(row.parsePortNm))]),
                                  _c("td", [_vm._v(_vm._s(row.cntrNo))]),
                                  _c("td", [
                                    _vm._v(_vm._s(row.parseGateStrDt)),
                                  ]),
                                  _c("td", [_vm._v(_vm._s(row.freeDds))]),
                                  _c("td", [
                                    _vm._v(_vm._s(row.parseFreeEndDt)),
                                  ]),
                                  _c(
                                    "td",
                                    [
                                      _c("e-date-picker", {
                                        attrs: { "stop-click-func": "Y" },
                                        on: {
                                          input: function ($event) {
                                            return _vm.changeExtsAlert(
                                              i,
                                              ...arguments
                                            )
                                          },
                                        },
                                        model: {
                                          value: row.extsDt,
                                          callback: function ($$v) {
                                            _vm.$set(row, "extsDt", $$v)
                                          },
                                          expression: "row.extsDt",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("td", [_vm._v(_vm._s(row.ovrDds))]),
                                  _c("td", [
                                    row.cntrSts === "Y"
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "label green md minwid50",
                                          },
                                          [_vm._v(_vm._s(row.status))]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            staticClass:
                                              "label red md minwid50",
                                          },
                                          [_vm._v(_vm._s(row.status))]
                                        ),
                                  ]),
                                  _c("td", { staticClass: "text_right" }, [
                                    _vm._v(
                                      _vm._s(_vm.numberFormat(row.totAmt))
                                    ),
                                  ]),
                                  _vm.ctrCd === "KR" ||
                                  _vm.ctrCd === "PH" ||
                                  _vm.vatChk
                                    ? _c("td", { staticClass: "text_right" }, [
                                        _vm._v(
                                          _vm._s(_vm.numberFormat(row.vatAmt))
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
            _c(
              "div",
              {
                staticStyle: { "padding-right": "3px" },
                style: _vm.headerFooterCss,
              },
              [
                _c("table", { staticClass: "tbl_col" }, [
                  _c("colgroup", [
                    _c("col", { staticStyle: { width: "5%" } }),
                    _c("col", { staticStyle: { width: "12%" } }),
                    _c("col", { staticStyle: { width: "10%" } }),
                    _c("col", { staticStyle: { width: "12%" } }),
                    _c("col", { staticStyle: { width: "14%" } }),
                    _c("col", { staticStyle: { width: "11%" } }),
                    _c("col", { staticStyle: { width: "12%" } }),
                    _c("col", { staticStyle: { width: "12%" } }),
                    _vm.ctrCd === "KR" || _vm.ctrCd === "PH" || _vm.vatChk
                      ? _c("col", { staticStyle: { width: "12%" } })
                      : _vm._e(),
                  ]),
                  _c("tfoot", [
                    _c("tr", [
                      _c(
                        "th",
                        {
                          staticClass: "text_right title",
                          attrs: {
                            colspan:
                              _vm.ctrCd === "KR" ||
                              _vm.ctrCd === "PH" ||
                              _vm.vatChk
                                ? 7
                                : 6,
                          },
                        },
                        [_vm._v("SUB TOTAL")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "text_right",
                          attrs: {
                            colspan:
                              _vm.ctrCd === "KR" ||
                              _vm.ctrCd === "PH" ||
                              _vm.vatChk
                                ? 1
                                : 2,
                          },
                        },
                        [_vm._v(_vm._s(_vm.numberFormat(_vm.subTotal[0])))]
                      ),
                      _vm.ctrCd === "KR" || _vm.ctrCd === "PH" || _vm.vatChk
                        ? _c("th", { staticClass: "text_right" }, [
                            _vm._v(_vm._s(_vm.numberFormat(_vm.subTotal[1]))),
                          ])
                        : _vm._e(),
                    ]),
                    _c("tr", [
                      _c(
                        "th",
                        {
                          staticClass: "text_right title",
                          attrs: {
                            colspan:
                              _vm.ctrCd === "KR" ||
                              _vm.ctrCd === "PH" ||
                              _vm.vatChk
                                ? 7
                                : 6,
                          },
                        },
                        [_vm._v("D/C AMOUNT")]
                      ),
                      _c("th", { staticClass: "text_right" }, [
                        _vm._v(_vm._s(_vm.dcRt) + " %"),
                      ]),
                      _c("th", { staticClass: "text_right" }, [
                        _vm._v(_vm._s(_vm.numberFormat(_vm.dcAmount))),
                      ]),
                    ]),
                    _c("tr", [
                      _c(
                        "th",
                        {
                          staticClass: "text_right title",
                          attrs: {
                            colspan:
                              _vm.ctrCd === "KR" ||
                              _vm.ctrCd === "PH" ||
                              _vm.vatChk
                                ? 7
                                : 6,
                          },
                        },
                        [_vm._v("GRAND TOTAL")]
                      ),
                      _c(
                        "th",
                        { staticClass: "text_right", attrs: { colspan: "2" } },
                        [_vm._v(_vm._s(_vm.numberFormat(_vm.grandTotal)))]
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
            _c("div", { staticClass: "mt10 calc-table-footer" }, [
              _c("div", [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.$router.history.current.name !==
                            "FreeTimeRequest" &&
                          Number(_vm.tableInfo.idx) ===
                            Number(_vm.tableInfo.tmpIdx),
                        expression:
                          "$router.history.current.name !== 'FreeTimeRequest' && Number(tableInfo.idx) === Number(tableInfo.tmpIdx)",
                      },
                    ],
                  },
                  [
                    _c(
                      "a",
                      {
                        class: _vm.auth.login
                          ? "button sm print ml5"
                          : "button sm print ml5 disabled",
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("_screenToPdfDownload")
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "btn_icon print" }),
                        _vm._v(_vm._s(_vm.$t("msg.SETT060G010.002")) + " "),
                      ]
                    ),
                  ]
                ),
                (_vm.query.eiCatCd == "I" && _vm.ctrCd === "KR") ||
                _vm.ctrCd === "PH" ||
                _vm.vatChk
                  ? _c("span", [
                      _c(
                        "a",
                        {
                          staticClass: "button sm ml5",
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function ($event) {
                              return _vm.fnDoKLNetSend()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.SETT060G010.022")))]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "position_relative",
                          staticStyle: { "margin-left": "5px" },
                          on: {
                            click: function ($event) {
                              return _vm.showTooltip(true)
                            },
                          },
                        },
                        [
                          _c("button", { staticClass: "tbl_icon help" }, [
                            _vm._v("도움말"),
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isToolTip,
                                  expression: "isToolTip",
                                },
                              ],
                              staticClass:
                                "tooltip_wrap short position_absolute",
                              staticStyle: {
                                width: "220px",
                                display: "none",
                                left: "30px",
                                top: "-111px",
                              },
                            },
                            [
                              _c("div", { staticClass: "cont" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "close",
                                    on: {
                                      click: function ($event) {
                                        return _vm.showTooltip(false)
                                      },
                                    },
                                  },
                                  [_vm._v("close")]
                                ),
                                _c("ul", { staticClass: "bul_list_sm t2" }, [
                                  _c("li", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("msg.SETT060G010.023")) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("div", [
                ["JP", "ID"].indexOf(_vm.ctrCd) >= 0 &&
                _vm.table.eiCatCd === "I"
                  ? _c(
                      "a",
                      {
                        class: _vm.btnCls.returnDateCancel,
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.returnDateCancelAlert()
                          },
                        },
                      },
                      [_vm._v("Return Date Cancel")]
                    )
                  : _vm._e(),
                ["JP", "ID"].indexOf(_vm.ctrCd) >= 0 &&
                _vm.table.eiCatCd === "I"
                  ? _c(
                      "a",
                      {
                        class: _vm.btnCls.returnDateSubmit,
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.returnDateSubmit()
                          },
                        },
                      },
                      [_vm._v("Return Date Submit")]
                    )
                  : _vm._e(),
                _vm.ctrCd === "KR"
                  ? _c(
                      "a",
                      {
                        class: _vm.btnCls.previousIssuedInvoicePop,
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.openPopup("PreviousIssuedInvoicePop")
                          },
                        },
                      },
                      [_vm._v("Invoice 발행내역")]
                    )
                  : _vm._e(),
                _vm.ctrCd === "KR"
                  ? _c(
                      "a",
                      {
                        class: _vm.btnCls.inputInfoVaAcctPop,
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.openVirtualAccountPopup(
                              "VirtualAccountInvoiceInfo"
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.ONIM099P010.053")))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.ctrCd === "KR"
                  ? _c(
                      "a",
                      {
                        class: _vm.btnCls.inputInfoPop,
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.openPopup("InputTaxInvoiceInfo")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.SETT060G010.013")))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    class: _vm.btnCls.proformaInvoicePop,
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("ProformaInvoicePop")
                      },
                    },
                  },
                  [_vm._v("Proforma Invoice")]
                ),
                _c(
                  "a",
                  {
                    class: _vm.btnCls.freeTimeExtensReqPop,
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("FreeTimeExtensReqPop")
                      },
                    },
                  },
                  [_vm._v("Freetime " + _vm._s(_vm.$t("msg.SETT060G010.014")))]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    class: _vm.btnCls.freeTimePrintPop,
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("FreeTimePrintPop")
                      },
                    },
                  },
                  [_vm._v("Freetime " + _vm._s(_vm.$t("msg.SETT060G010.015")))]
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }