var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "1300px" } },
    [
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isCloseBtn,
              expression: "isCloseBtn",
            },
          ],
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c(
        "div",
        { staticClass: "popup_cont" },
        [
          _c("h1", { staticClass: "page_title" }, [
            _vm._v("Invoice 발행 내역"),
          ]),
          _c("div", { staticClass: "content_box" }, [
            _c("table", { staticClass: "tbl_col" }, [
              _vm._m(0),
              _vm._m(1),
              _vm.invoiceList.length > 0
                ? _c(
                    "tbody",
                    _vm._l(_vm.invoiceList, function (t, i) {
                      return _c("tr", { key: "invoice-" + i }, [
                        t.isFirst
                          ? _c("td", { attrs: { rowspan: t.rowspan } }, [
                              _c(
                                "a",
                                {
                                  staticClass: "link",
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.openPopup("demInvoicePop", i)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(t.invIssNo))]
                              ),
                            ])
                          : _vm._e(),
                        t.isFirst
                          ? _c("td", { attrs: { rowspan: t.rowspan } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$ekmtcCommon.changeDatePattern(
                                    t.issDt,
                                    "."
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                        _c(
                          "td",
                          {
                            staticStyle: {
                              "border-left": "1px solid #e1e3eb !important",
                            },
                          },
                          [_vm._v(_vm._s(t.curCd))]
                        ),
                        _c("td", [_vm._v(_vm._s(_vm._f("comma")(t.locAmt)))]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.getDemDetCatNm(t.demDetCatCd))),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(t.cntrSzCd))]),
                        _c("td", [_vm._v(_vm._s(t.cntrTypCd))]),
                        _c("td", [_vm._v(_vm._s(t.cntrQty))]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.$ekmtcCommon.changeDatePattern(t.extsDt, ".")
                            )
                          ),
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(t.rcvCpltYn === "Y" ? "paid" : "No paid")
                          ),
                        ]),
                        t.isFirst
                          ? _c("td", { attrs: { rowspan: t.rowspan } }, [
                              t.apvStsCd === "입금완료" ||
                              t.apvStsCd === "요청" ||
                              t.apvStsCd === "승인" ||
                              t.apvStsCd === "거절" ||
                              t.apvStsCd === "처리중" ||
                              t.apvStsCd === "취소"
                                ? _c("span", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "link",
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            _vm.openPopup(
                                              "CalcIssueInvMsgPop",
                                              i,
                                              _vm.getDemDetTableIdx(
                                                t.demDetCatCd
                                              )
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(t.apvStsCd))]
                                    ),
                                  ])
                                : _c("span", [_vm._v(_vm._s(t.apvStsCd))]),
                            ])
                          : _vm._e(),
                        t.isFirst
                          ? _c("td", { attrs: { rowspan: t.rowspan } }, [
                              t.vaAccntCd === "01"
                                ? _c("span", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "button sm",
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.createVaAcct(
                                              t.invIssNo,
                                              t.locAmt
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("가상계좌생성")]
                                    ),
                                  ])
                                : _vm._e(),
                              t.vaAccntCd === "02"
                                ? _c("span", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "button red sm",
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.fnVaAcctCnclYn(
                                              $event,
                                              t.invIssNo
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("취소")]
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        t.isFirst
                          ? _c(
                              "td",
                              {
                                staticStyle: { color: "red" },
                                attrs: { rowspan: t.rowspan },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$ekmtcCommon.isEmpty(t.vaAccntNo)
                                      ? ""
                                      : t.vaAccntNo + "(하나은행)"
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    }),
                    0
                  )
                : _c("tbody", [_vm._m(2)]),
            ]),
          ]),
          _c("div", { staticClass: "mt10 text_center" }, [
            _c(
              "a",
              {
                staticClass: "button blue lg",
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.closePopup.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.CMBA100.00261")))]
            ),
          ]),
          _c(
            "win-layer-pop",
            { staticClass: "sample_popup_list" },
            [
              _vm.customComponent
                ? _c(_vm.customComponent, {
                    tag: "component",
                    attrs: { "parent-info": _vm.parentInfoRe },
                    on: { close: _vm.closePopup },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "7%" } }),
      _c("col", { staticStyle: { width: "7%" } }),
      _c("col", { staticStyle: { width: "7%" } }),
      _c("col", { staticStyle: { width: "7%" } }),
      _c("col", { staticStyle: { width: "6%" } }),
      _c("col", { staticStyle: { width: "6%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "6%" } }),
      _c("col", { staticStyle: { width: "6%" } }),
      _c("col", { staticStyle: { width: "9%" } }),
      _c("col", { staticStyle: { width: "16%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Invoice No.")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Issue Date")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Currency")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Amount")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("DEM/DET")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Size")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Type")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Container Quantity")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Extend Date")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Paid")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Status")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("가상계좌")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("가상계좌번호(은행) ")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "13" } }, [_vm._v("No data")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }